<template>
  <div id="overview" class="w-full flex flex-col items-center">
    <SectionHeading>Main topics we will cover in this bootcamp</SectionHeading>

    <img class="w-[900]" src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_900/website/prsls-mindmap.png">

    <TestimonialSlim image="nader-dabit.jpg">
      <template v-slot:quote>
        "By far the <span class='underline underline-offset-2 text-red-500'>most comprehensive</span>, <span class='underline underline-offset-2 text-red-500'>concise</span>, and <span class='underline underline-offset-2 text-red-500'>up to date</span> course for building real-world serverless applications."
      </template>
      <template v-slot:author>
        - Nader Dabit, Author of "Full Stack Serverless"
      </template>
    </TestimonialSlim>
  </div>
</template>

<script>
import TestimonialSlim from './TestimonialSlim.vue'

export default {
  name: 'OverviewSection',
  components: {
    TestimonialSlim
  }
}
</script>
