<template>
  <div class="bg-white py-24 sm:py-24">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="text-center">
          <SectionHeading>Trusted by Serverless Experts</SectionHeading>
        </div>
        <dl class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-3">
          <div v-for="stat in stats" :key="stat.id" class="flex flex-col bg-gray-400/5 p-8">
            <dt class="text-sm/6 font-semibold text-gray-600">{{ stat.name }}</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">{{ stat.value }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
const stats = [
  { id: 1, name: 'AWS Heros & Community Builders', value: '20+' },
  { id: 2, name: 'Happy Students', value: '1,000+' },
  { id: 4, name: 'Overall Satisfaction', value: '4.72 / 5' }
]
</script>
