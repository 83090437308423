<template>
  <h3 class="text-3xl font-semibold mb-10">This Bootcamp</h3>
  <div class="flex flex-col gap-y-4 text-lg">
    <!-- vs. other bootcamps -->
    <ProOrCon v-if="state.vsOtherWorkshops" :isPro=true>
      <p><strong>More affordable</strong>.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsOtherWorkshops" :isPro=true>
      <p>Supports <strong>purchase power parity</strong> (PPP) for 161 countries.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsOtherWorkshops" :isPro=true>
      <p>Taught by a respected practitioner with ample <strong>real-world experience</strong>
        of building large serverless architectures.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsOtherWorkshops" :isPro=true>
      <p><strong>Lifetime access</strong> to the bootcamp material.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsOtherWorkshops" :isPro=true>
      <p><strong>Lifetime access</strong> to my private Slack channel.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsOtherWorkshops" :isPro=true>
      <p>True <strong>end-to-end training</strong> designed to prepare you for the
        real-world: from architecture design to writing code, testing, CI/CD, security
        and observability best practices. <strong>Everything you need for building
        production-ready serverless applications</strong>.</p>
    </ProOrCon>

    <!-- vs. isolated resources -->
    <ProOrCon v-if="state.vsIsolatedResources" :isPro=true>
      <p><strong>Structured learning path</strong> that covers all essential topics.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsIsolatedResources" :isPro=true>
      <p>Consistent teaching style, methodology and quality.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsIsolatedResources" :isPro=true>
      <p>Practical advice illustrated with <strong>real-world examples</strong>.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsIsolatedResources" :isPro=true>
      <p>Receive <strong>tailored advice</strong> for your specific challenges.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsIsolatedResources" :isPro=true>
      <p>Learn from your peers and expand your professional network at the same time.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsIsolatedResources" :isPro=true>
      <p><strong>Continuously updated</strong> to align with latest industry trends and best practices.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsIsolatedResources" :isPro=true>
      <p><strong>More cost-effective</strong> than purchasing multiple courses.</p>
    </ProOrCon>
    <ProOrCon v-if="state.vsIsolatedResources" :isPro=true>
      <p>Your one-stop shop to give you a <strong>complete understanding of serverless architectures</strong>.</p>
    </ProOrCon>
  </div>
</template>

<script>
import ProOrCon from './ProOrCon.vue'

export default {
  props: ['state'],
  components: {
    ProOrCon
  }
}
</script>
