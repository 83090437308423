<template>
  <div id="about" class="max-w-7xl mx-auto px-2">
    <h1 class="text-3xl md:text-4xl font-extrabold">Hey, I'm Yan.</h1>

    <div class="mt-5 flex flex-col gap-y-8">
      <div class="flex flex-col sm:flex-row gap-x-8 gap-y-4 place-items-center">
        <img class="h-28 rounded-full" loading="lazy"
          src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_112/website/mugshot.png" />
        <p class="text-2xl col-span-4">
          I'm an
          <a class="font-extrabold underline"
            href="https://aws.amazon.com/developer/community/heroes/yan-cui/"
            target="_blank">
            AWS Serverless Hero</a>.
          I have run production workloads on AWS since 2010 and have helped
          thousands of developers get up to speed with AWS and serverless.
        </p>
      </div>
      <div class="text-gray-800 md:text-lg lg:text-xl flex flex-col gap-y-6">
        <p>
          I have been an architect and principal engineer with a variety of
          industries including banking, e-commerce, social networks, live streaming
          and mobile gaming. I have worked on systems that handle millions of
          concurrent users and process billions of events per day.
        </p>
        <p>
          Nowadays, I work as an independent consultant and help clients go faster
          for less by adopting serverless. I have implemented many large serverless
          systems (see
          <a class="underline" href="https://www.youtube.com/watch?v=XheZQya39H0" target="_blank">here</a>,
          <a class="underline" href="https://www.youtube.com/watch?v=evsz__BDprs" target="_blank">here</a>,
          and
          <a class="underline" href="https://theburningmonk.com/case-studies-eldercounsel" target="_blank">here</a>).
        </p>
        <p>
          When I started my serverless journey in 2016, I had to figure everything out.
          I made lots of mistakes and I learnt from them.
          I have designed this bootcamp to teach you what works in weeks and save you
          years of trial-and-error.
        </p>
      </div>
    </div>

    <div class="hidden mt-14 sm:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
      <a :href="tile.url" target="_blank" v-for="tile in tiles"  v-bind:key="tile.name">
        <figure class="img-hover-zoom overlay relative">
          <figcaption class="z-50 absolute opacity-80 bottom-3 text-gray-200 text-center text-lg font-medium w-full">{{ tile.msg }}</figcaption>
            <img class="z-0" loading="lazy"
              :src="`https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto/${tile.img}`" >
        </figure>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
  data () {
    return {
      tiles: [{
        name: 'speaking',
        msg: 'spoken at over 150 events',
        img: 'about-speaking.png',
        url: 'https://theburningmonk.com/speaking-engagements'
      }, {
        name: 'work',
        msg: 'helped many clients',
        img: 'about-work.png',
        url: 'https://theburningmonk.com/hire-me'
      }, {
        name: 'book',
        msg: 'written some books',
        img: 'about-book.png',
        url: 'https://www.manning.com/books/serverless-architectures-on-aws-second-edition?a_aid=aws-lambda-in-motion&a_bid=9318fc6f'
      }, {
        name: 'course',
        msg: 'published online courses',
        img: 'about-course.png',
        url: 'https://theburningmonk.com/courses'
      }, {
        name: 'blog',
        msg: 'blogged for 15+ years',
        img: 'about-blog.png',
        url: 'https://theburningmonk.com'
      }, {
        name: 'podcast',
        msg: 'host a serverless podcast',
        img: 'about-podcast.png',
        url: 'https://realworldserverless.com'
      }]
    }
  }
}
</script>

<style scoped>
.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform 0.2s ease-out;
}

.img-hover-zoom:hover img {
  transform: scale(1.2);
}

.overlay::before {
  content: '';
  z-index: 10;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%);
}
</style>
