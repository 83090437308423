const PRICE = 840
const PRICE_FULL = 1200
const INSTALLMENT_PRICE = 188
const INSTALLMENT_PRICE_FULL = 260
const INSTALLMENTS = 5
const START_DATE = 'Apr 7th, 2025'
const CLOSE_DATE = 'Apr 13th'
const EARLY_BIRD_DEADLINE = new Date('2025-03-03T00:00:00Z')
const EARLY_BIRD_DATE = 'Mar 3rd, 2025'
const SINGLE_PAYMENT_ID = 'prsls-apr-2025'
const INSTALLMENT_ID = 'prsls-apr-2025-instalments'
const BE_URL = 'https://ipwkqgl8cg.execute-api.eu-west-1.amazonaws.com/dev'

export {
  PRICE,
  PRICE_FULL,
  INSTALLMENT_PRICE,
  INSTALLMENT_PRICE_FULL,
  INSTALLMENTS,
  START_DATE,
  CLOSE_DATE,
  EARLY_BIRD_DEADLINE,
  EARLY_BIRD_DATE,
  SINGLE_PAYMENT_ID,
  INSTALLMENT_ID,
  BE_URL
}
