<template>
  <div class="pt-36 max-w-5xl mx-auto min-h-screen grid grid-cols-1 justify-items-center gap-10 content-start">

    <h1 class="corinthia text-9xl mb-16">Thank you!</h1>

    <div class="text-lg px-5">
      <h2 class="text-center text-3xl font-bold mb-5">What happens now?</h2>
      <p>
        By now you should have received a payment receipt from
        <a href="https://www.lemonsqueezy.com/" target="_blank" class="font-bold">Gumroad</a>
        by email. Please keep this safe as proof of your purchase.</p>
      <p>
        I would follow up with everyone a week before the bootcamp start date and
        give you instructions on how to join the bootcamp and any pre-requisites
        you need to install/download.
      </p>
      <p>
        In the meantime, if you have any questions, please reach out to me at
        <a class="link underline" target="_blank"
          href="mailto:theburningmonk@hey.com?subject=Question%20about%20Production-Ready%20Serverless%20workshop">
          theburningmonk@hey.com</a>.
      </p>
      <p>
        ps. if you have purchased a group ticket, then I will soon reach out to you
        to get the names and emails of the students. You can also send those to me at
        <a class="link underline" target="_blank"
          href="mailto:theburningmonk@hey.com?subject=Group%20ticket%20attendees%20for%20Production-Ready%20Serverless%20workshop">
          theburningmonk@hey.com</a>
        along with the receipt of your purchase.
      </p>
    </div>

    <a class="font-medium underline" href="/">Back to home</a>

    <div class="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
      <p class="mt-8 text-base md:mt-0 md:order-1">&copy; 2025 theburningmonk LTD. All rights reserved.</p>
    </div>
  </div>
</template>

<style scoped>
  p {
    margin: 2rem;
  }

  .corinthia {
    font-family: 'Corinthia';
  }
</style>

<script>
export default {
  mounted () {
    document.title = 'Thank you for your purchase!'
  }
}
</script>
