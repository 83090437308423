async function getDiscount () {
  const resp = await fetch('https://api.paritydeals.com/api/v1/deals/discount/?url=https://productionreadyserverless.com/')
  const data = await resp.json()
  // const data = {
  //   bar: {
  //     backgroundColor: '#3182CE',
  //     backgroundTransparency: '0',
  //     fontColor: '#F9F9F9',
  //     highlightFontColor: '#DD5C64',
  //     placement: 'top',
  //     borderRadius: '0px',
  //     addCloseIcon: true,
  //     unStyled: true,
  //     container: '#banner',
  //     fontSize: '1.3rem'
  //   },
  //   couponCode: 'HOLIDAYS24',
  //   discountPercentage: '25.00',
  //   holiday: 'Christmas Eve and Day',
  //   data: {},
  //   country: 'Netherlands',
  //   countryFlag: '🇳🇱',
  //   countryFlagSvg: '<svg  viewBox="0 0 132 99" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_dd_1104_22125)"><g clip-path="url(#clip0_1104_22125)"><rect x="7.5" y="3" width="117.6" height="84" rx="9.14286" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 59.0003H125.1V87.0003H7.5V59.0003Z" fill="#1E448D"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 3H125.1V31H7.5V3Z" fill="#B01923"/></g></g><defs><filter id="filter0_dd_1104_22125" x="0.642857" y="0.714285" width="131.314" height="97.7143" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4.57143"/><feGaussianBlur stdDeviation="3.42857"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.08 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1104_22125"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="0.285714"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.32 0"/><feBlend mode="normal" in2="effect1_dropShadow_1104_22125" result="effect2_dropShadow_1104_22125"/><feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1104_22125" result="shape"/></filter><clipPath id="clip0_1104_22125"><rect x="7.5" y="3" width="117.6" height="84" rx="9.14286" fill="white"/></clipPath></defs></svg>',
  //   time: null,
  //   message: "<div class='parity-banner  '><div class='parity-banner-inner'><b>Christmas Eve and Day</b> sale is on! A <b>40%</b> off discount has been applied to your order.</div><button type='button' class='parity-banner-close-btn' aria-label='Close'><svg viewBox='0 0 24 24' focusable='false' aria-hidden='true'><path fill='currentColor' d='M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z'></path></svg></button></div>",
  //   messageText: '<b>Christmas Eve and Day</b> sale is on! A <b>40%</b> off discount has been applied to your order.',
  //   countryCode: 'NL',
  //   currencyCode: 'EUR',
  //   currencySymbol: '€',
  //   usdConversionRate: '0.958751',
  //   isVpn: false,
  //   isProxy: false,
  //   isTor: false
  // }

  if (data?.couponCode) {
    const discountPercentage = parseFloat(data.discountPercentage) || 0

    console.log('coupon code:', data.couponCode)
    console.log('discount %:', discountPercentage)
    console.log('holiday name: ', data.holiday)

    return {
      couponCode: data.couponCode,
      discount: discountPercentage,
      holiday: data.holiday
    }
  } else {
    return {
      discount: 0
    }
  }
}

module.exports = {
  getDiscount
}
