<template>
  <div>
    <SectionHeading>Best suited for people with these backgrounds</SectionHeading>

    <ul class="text-lg sm:text-xl flex flex-wrap justify-center gap-x-8 gap-y-4 max-w-4xl mx-auto">
      <li v-for="role of targetAudience" :key="role" class="basis-[75%] sm:basis-[30%] flex shrink:0 items-center gap-1">
        <CircleTick class="h-8 text-green-600"/>{{ role }}
      </li>
    </ul>

    <SectionHeading>Who this bootcamp is <span class="font-extrabold text-red-500">NOT</span> for:</SectionHeading>

    <div class="text-lg sm:text-xl text-center">
      <p>If you have no IT background, it's best to study another bootcamp first.</p>
      <p>Basic understanding of coding is required.</p>
    </div>
  </div>
</template>

<script>
import CircleTick from '@/components/CircleTick.vue'

export default {
  components: {
    CircleTick
  },
  data () {
    return {
      targetAudience: [
        'Backend Engineers',
        'Cloud Engineers',
        'DevOps Engineers',
        'Tech Leads',
        'Fullstack Engineers',
        'Architects',
        'Security Engineers',
        'Test Engineers / QA'
      ]
    }
  }
}
</script>
