<template>
  <div class="flex flex-col items-center text-center">
    <img class="h-64 w-64" loading="lazy" src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_256,w_256/website/happiness-guarantee.png" />

    <h2 class="text-lg max-w-2xl mt-2">
      If this bootcamp doesn't meet your expectations, you're entitled to a <strong>FULL REFUND</strong>.
      Simply reach out within the <strong>first two weeks</strong> of the bootcamp.
    </h2>
  </div>
</template>
