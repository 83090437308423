<template>
  <div class="relative mt-8 group">
    <div class="px-8 py-4 rounded-lg sm:rounded-full border border-blue-300 hover:border-blue-500 transition-colors text-gray-500 text-lg">
      <span class="italic font-semibold">
        <slot name="quote"></slot>
      </span>
      <span>
        <slot name="author"></slot>
      </span>
    </div>
    <img
      :src="`https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_80,w_80/website/${image}`"
      class="absolute -right-4 -bottom-4 w-20 h-20 rounded-full border-2 border-white">
  </div>
</template>

<script>
export default {
  name: 'TestimonialSlim',
  props: ['image']
}
</script>
