<template>
  <div id="faq">
    <div class="max-w-7xl mx-auto px-4 divide-y-2 divide-gray-200 sm:px-6 lg:px-8">
      <SectionHeading>FAQs</SectionHeading>

      <div class="mt-6 pt-10">
        <FaqQuestion question="What if I change my mind after I purchased my ticket?">
          <p class="text-lg text-gray-600">
            No problems. If you change your mind for whatever reasons, you can get
            a full refund even after the bootcamp has started. See our
            <strong>happiness guarantee</strong> above.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Will I have access to the videos and code for the bootcamp?">
          <p class="text-lg text-gray-600">
            Yes! All the code and lecture materials will be available to you after
            the bootcamp, <span class="font-extrabold text-black">forever</span>.
          </p>
        </FaqQuestion>

        <FaqQuestion question="What are the prerequisites for taking this bootcamp?">
          <p class="text-lg text-gray-600">
            I assume you have some basic understanding of AWS, knows how to navigate
            your way around the AWS console. <br/>
            Experience with Lambda, CloudFormation and other relevant services
            would be a great help, but they will be covered in the introductory
            part of this bootcamp. <br/>
            Also, the exercises in this bootcamp will be carried out in Node.js
            but you don't need to be an expert. I will give you detailed
            instructions and provide you with all the code you need to write.
            But it'll be helpful if you can understand basic Javascript and
            knows how to install packages from NPM.
          </p>
        </FaqQuestion>

        <FaqQuestion question="I'm running serverless in production already, is this bootcamp still relevant for me?">
          <p class="text-lg text-gray-600">
            Yes! I have trained many engineers with production experience of
            serverless technologies and helped
            <span class="font-extrabold text-black">raise their game even further!</span>
            The practical skills from this bootcamp would help you
            <span class="font-extrabold text-black">make better architectural decisions</span>
            when it comes to both AWS and serverless.<br/>
            In fact, those that get the most from this bootcamp tend to have some
            production experience with serverless technologies in AWS already.
            Because they often have specific questions or challenges they are
            facing, and I can help them address those questions or challenges
            during the bootcamp.
          </p>
        </FaqQuestion>

        <FaqQuestion question="How is this different from other courses on the market?">
          <p class="text-lg text-gray-600">
            The main difference is that I am the one teaching it. I have lived
            and breathed serverless for a long time and everything I teach you
            come from years of experience of running this in production. <br/>
            This is not a surface-level type of tutorial,
            <span class="font-extrabold text-black">it's in-depth and intensive,</span>
            and teaches you
            <span class="font-extrabold text-black">skills you will need in the real-world</span>.
            These practical skills are also highly sought after in the market
            and valued by employers.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Is there any kind of certificate of completion?">
          <p class="text-lg text-gray-600">
            Yes, there is. A certificate of completion will be issued once you complete all
            the bootcamp materials.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Do you support Purchase Power Parity (PPP)?">
          <p class="text-lg text-gray-600">
            Yes, we support PPP for <strong>161 countries</strong>.
            In fact, an appropriate discount is automatically applied for your order during checkout.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Can I pay in installments instead of a lump sum?">
          <p class="text-lg text-gray-600">
            Yes, you can pay in {{ installments }} installments of ${{ installmentPrice }} each,
            for a total of ${{ installmentPrice * installments }}.
            If you have a discount code, they can be used on the installment payments too.
            Please contact me at
            <a class="link underline" target="_blank"
              href="mailto:theburningmonk@hey.com?subject=Installments%20for%20Production-Ready%20Serverless">
              theburningmonk@hey.com
            </a> if you have any questions about paying in installments.
          </p>
        </FaqQuestion>

        <FaqQuestion question="I can't afford your course, is there anything you can do to help?">
          <p class="text-lg text-gray-600">
            Yes, absolutely. Drop me an email at
            <a class="link underline" target="_blank"
              href="mailto:theburningmonk@hey.com?subject=Help%20with%20purchasing%20Production-Ready%20Serverless">
              theburningmonk@hey.com
            </a>
            and explain your situation to me. Maybe you're a student, or you're
            out-of-work, or maybe the price is just too high for your country,
            whatever the case, I would love to help you out.
          </p>
        </FaqQuestion>

        <FaqQuestion question="I want to send my whole team. Do you offer group discounts?">
          <p class="text-lg text-gray-600">
            Yes, we do! Send your query to
            <a class="link underline" target="_blank"
              href="mailto:theburningmonk@hey.com?subject=Group%20discount%20for%20Production-Ready%20Serverless">
              theburningmonk@hey.com
            </a>
            and we can work out a discount for you.
          </p>
        </FaqQuestion>

        <FaqQuestion question="Do you offer this as an in-house training (either on-site or remote)?">
          <p class="text-lg text-gray-600">
            Yes, we do! Send your query to
            <a class="link underline" target="_blank"
              href="mailto:theburningmonk@hey.com?subject=In-house%20training%20for%20Production-Ready%20Serverless">
              theburningmonk@hey.com
            </a>
            and we can work something out with you.
          </p>
        </FaqQuestion>

        <FaqQuestion question="How do I convince my manager to let me attend?">
          <div class="text-lg text-gray-600">
            Trying to convince your manager to let you attend the bootcamp? I've created
            a customizable email template that you can use to prove the value of your
            attendance:<br/>
            <br/>
            <div class="italic">
              Dear [your manager's name],<br/>
              <br/>
              I'd like your permission to attend this serverless bootcamp by Yan Cui,
              who is an AWS Serverless Hero and has several years of experience of
              actually running serverless applications in production with AWS Lambda
              at scale.<br/>
              [insert URL of the bootcamp you want to attend]<br/>
              The bootcamp is happening on [insert bootcamp dates].<br/>
              Attending this bootcamp will be beneficial to both my personal growth
              and our company's cloud knowledge in the following areas:<br/>
              <ul class="list-inside list-disc">
                <li>
                  Boost proficiency in AWS's serverless offerings, like AWS Lambda,
                  API Gateway, Cognito and EventBridge.
                </li>
                <li>
                  Learn about the latest features of AWS Lambda such as Provisioned
                  Concurrency and Function URLs and when to use them.
                </li>
                <li>
                  Get educated on architectural and operational best practices on
                  topics such as CI/CD, security, testing, logging and monitoring.
                </li>
                <li>
                  Gain valuable hands-on experience which I can bring back to my
                  team and apply right away.
                </li>
              </ul>
              <br/>
              The cost for the bootcamp is [insert ticket price for the bootcamp
              you want to attend].<br/>
              Hope to hear from you soon and many thanks in advance.<br/>
              <br/>
              Kind regards,
            </div>
          </div>
        </FaqQuestion>
      </div>
    </div>
  </div>
</template>

<script>
import FaqQuestion from './FaqQuestion.vue'
import { INSTALLMENTS, INSTALLMENT_PRICE } from '../libs/constants'

export default {
  components: {
    FaqQuestion
  },
  data () {
    return {
      installmentPrice: INSTALLMENT_PRICE,
      installments: INSTALLMENTS
    }
  }
}
</script>
