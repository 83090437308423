<template>
  <li v-if="!hideOnMobile" :class="`flex place-self-center relative w-[${pxSize}px]`">
    <img loading="lazy" :src="src" :alt="company">
  </li>
  <li v-else :class="`hidden sm:flex place-self-center relative w-[${pxSize}px]`">
    <img loading="lazy" :src="src" :alt="company">
  </li>
</template>

<script>
export default {
  name: 'LogoComponent',
  props: ['company', 'logo', 'hideOnMobile', 'isLarge'],
  data () {
    const pxSize = this.isLarge === true ? 160 : 96

    const url = 'https://res.cloudinary.com/prsls/image/upload/'
    const src = `${url}/f_auto,c_scale,w_${pxSize}/${this.logo}`

    return {
      src
    }
  }
}
</script>
