<template>
  <div id="logos" class="lg:px-8 w-full mx-auto my-6">
    <SectionHeading>Trusted by Companies Around the World</SectionHeading>
    <p class="text-center text-lg -mt-4 mb-10 text-gray-500">
      Enpower your team to <span class="font-bold italic">go faster for less</span> by adopting serverless the right way.
    </p>

    <ul class="flex flex-wrap justify-center gap-x-8 gap-y-4 max-w-4xl mx-auto">
      <Logo company="AWS" logo="website/logo-aws.png" />
      <Logo :isLarge=true company="Thoughtworks" logo="website/logo-wide-thoughtworks.png" />
      <Logo :isLarge=true company="Adobe" logo="website/logo-wide-adobe.png" />
      <Logo :isLarge=true company="CapitalOne" logo="website/logo-wide-capitalone.png" />
      <Logo :isLarge=true company="Siemens" logo="website/logo-wide-siemens.png" />
      <Logo company="NordCloud" logo="website/logo-nordcloud.png" />
      <!-- <Logo :isLarge=true company="Qualcomm" logo="website/logo-wide-qualcomm.png" /> -->
      <Logo company="Lego" logo="website/logo-lego.png" />
      <Logo company="Booking" logo="website/logo-booking.png" />
      <Logo company="Post NL" logo="website/logo-postnl.png" />
      <Logo company="Volkswagen" logo="website/logo-volkswagen.png" />
      <Logo company="Novo Nordisk" logo="website/logo-novo_nordisk.png" />
      <!-- <Logo company="HBO" logo="website/logo-hbo.png" />
      <Logo company="Taco Bell" logo="website/logo-taco-bell.png" :hideOnMobile=true />
      <Logo company="Autodesk" logo="website/logo-autodesk.png" />
      <Logo company="Post NL" logo="website/logo-postnl.png" />
      <Logo company="Novo Nordisk" logo="website/logo-novo_nordisk.png" :hideOnMobile=true />
      <Logo company="Qualcomm" logo="website/logo-qualcomm.png" :hideOnMobile=true />
      <Logo company="PWC" logo="website/logo-pwc.png" />
      <Logo company="NN Group" logo="website/logo-nn.png" :hideOnMobile=true />
      <Logo company="Fender" logo="website/logo-fender.png" :hideOnMobile="true" />
      <Logo company="Veho" logo="website/logo-veho.png" :hideOnMobile="true" />
      <Logo company="Medtronic" logo="website/logo-medtronic.png" />
      <Logo company="Dunelm" logo="website/logo-dunelm.png" :hideOnMobile=true />
      <Logo company="Crowdcube" logo="website/logo-crowdcube.png" :hideOnMobile=true />
      <Logo company="Adobe" logo="website/logo-adobe.png" /> -->
    </ul>
  </div>
</template>

<script>
import Logo from './Logo.vue'

export default {
  name: 'LogosComponent',
  components: {
    Logo
  }
}
</script>
